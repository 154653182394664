import React, { useEffect, useState } from 'react';
import './Attributions.css';
import SimpleNavBar from '../components/SimpleNavBar';
import BackroomsStore from '../components/BackroomsStore';
import axios from 'axios';
import {Container, Nav} from "react-bootstrap";

const Attributions = () => {
    const [attributions, setAttributions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch all ORC titles from the API
    useEffect(() => {
        const fetchAttributions = async () => {
            try {
                const response = await axios.get('/api/v1/orc-titles/get-all-titles');
                const sortedAttributions = response.data.sort((a, b) => {
                    // Convert the year_published to Date and compare them
                    return new Date(a.year_published) - new Date(b.year_published);
                });
                setAttributions(sortedAttributions);
                setLoading(false);
            } catch (err) {
                setError('Failed to load attributions');
                setLoading(false);
            }
        };

        fetchAttributions();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <Container>
            <SimpleNavBar />
            <div className="container">
                <h1>Attributions</h1>
                <p>This page lists all the Paizo ORC Licensed titles and their associated attributions that are being
                    referenced in the development of pfOracle and Backrooms dot Net rules. <a href="https://backrooms.net">Backrooms dot Net</a> and&nbsp;<a href="/pforacle">pfOracle</a> are not affiliated with Paizo, Pathfinder, or Starfinder.</p>

                <div className="attributions-list">
                    {attributions.map((attribution) => (
                        <div key={attribution.id} className="attribution-item">
                            <h2>
                                {attribution.purchase_link ? (
                                    <a href={attribution.purchase_link} target="_blank" rel="noopener noreferrer">
                                        {attribution.title}
                                    </a>
                                ) : (
                                    attribution.title
                                )}
                            </h2>
                            <p><strong>Year Published:</strong> {new Date(attribution.year_published).getFullYear()}</p>
                            <p><strong>Attributions:</strong> {attribution.attributions}</p>

                            {attribution.image_link && (
                                <img
                                    src={attribution.image_link}
                                    alt={`${attribution.title} Cover`}
                                    className="attribution-image"
                                    style={{ width: '200px', height: 'auto' }}
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <BackroomsStore />
        </Container>
    );
};

export default Attributions;
